import { render, staticRenderFns } from "./wholesele.vue?vue&type=template&id=72d9bc68&scoped=true&"
import script from "./wholesele.vue?vue&type=script&lang=js&"
export * from "./wholesele.vue?vue&type=script&lang=js&"
import style0 from "./wholesele.vue?vue&type=style&index=0&id=72d9bc68&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d9bc68",
  null
  
)

export default component.exports