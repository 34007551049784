<template>
  <div>
    
   <h1>法人販売</h1>
   
   <router-link @click.native="scrollToTop" to="qa">Ｑ＆Ａ</router-link> | 
   <router-link @click.native="scrollToTop" to="contact">お問い合わせ</router-link>

<!--   <router-view></router-view>-->

   
   <div class="contactform" v-if="view!='complete'">
     <div v-if="view=='confirm'">

       <strong>まだ送信は完了していません。</strong><br>以下の内容で送信してよろしいですか？
       <div class="bns">
           <button @click="prev">前に戻る</button>
           <button @click="submit">この内容を送信</button>
           </div>

           
          <table>
            <tbody>
              <tr>
                <th colspan="2" class="formListTh3">法人販売申込書送信フォーム</th>
              </tr>
              <tr>
                <th align="right" class="formListTh">お名前：</th>
                <td align="left" class="formListTd">{{name}}</td>
              </tr>
              <tr>
                <th align="right" class="formListTh">メールアドレス：</th>
                <td align="left" class="formListTd">{{email}}</td>
              </tr>
            <tr>
                <th align="right" class="formListTh">お問合せメッセージ：</th>
                <td align="left" class="formListTd">{{message}}</td>
              </tr>
            </tbody>
          </table> 

          
       <div class="bns">
           <button @click="prev">前に戻る</button>
           <button @click="submit">この内容を送信</button>
           </div>

     </div>
     <div v-show="view=='edit'">
         <p class="tinfo">
         このページでは、法人販売などの大量購入をお考えの方のために、あらかじめ送信させていただいた申込書の受付を行うフォームです。<br>
         これから大量購入をお考えの方はお問い合わせページよりお問い合わせください。</p>
          <table>
            <tbody>
              <tr>
                <th colspan="2" class="formListTh3">申込書受付フォーム</th>
              </tr>
              <tr>
                <th align="right" class="formListTh">申込書ファイル：</th>
                <td align="left" class="formListTd">
						<input type="file" name="申込書" id="upfile" class="upFile">
                *必須</td>
              </tr>
              <tr>
                <th align="right" class="formListTh">お名前：</th>
                <td align="left" class="formListTd"><input v-model="name" name="name" type="text" class="fm1" value="" size="20">
                *必須</td>
              </tr>
              <tr>
                <th align="right" class="formListTh">メールアドレス：</th>
                <td align="left" class="formListTd"><input v-model="email" name="email" type="text" class="fm1" value="" size="20">
                *必須</td>
              </tr>
			<tr>
                <th align="right" class="formListTh">メールアドレス（確認）：</th>
                <td align="left" class="formListTd"><input v-model="email_c" name="email2" type="text" class="fm1" value="" size="20">
                *必須</td>
            </tr>
            <tr>
                <th align="right" class="formListTh">お問合せメッセージ：</th>
                <td align="left" class="formListTd">*必須<br><textarea v-model="message" name="msg" cols="30" rows="6" class="fm1"></textarea></td>
              </tr>
            </tbody>
          </table> 
           <button @click="confirm">送信内容の確認</button>
          </div>     
       
</div>
<div class="contactform_end" v-else>
  ありがとうございます。送信を完了しました。
</div>



    <dialogalert
      v-model="visible_dialog"
      :title="dialog_title"
      :info="dialog_info"
      :button_ok="dialog_bn_ok"
      :button_cancel="dialog_bn_cancel"
      :ok_fn="dialog_ok_fn"
    />


  </div>
</template>

<script>



export default  {
  beforeRouteLeave(to, from, next) {
    if(this.name || this.subject || this.number | this.email || this.message){
      if(window.confirm("編集中のものは保存されませんが、よろしいですか？")){
          next();
        }else{
          return;
        }
    }else{
      next();
    }
  },
  data:function(){
    return {
      name:"",subject:"",number:"",email:"",email_c:"",message:"",file:"",
      view:"edit",mode:"",
          fileInfo: '',
    }
  },
    created: function() { 
      this.$parent.middle = 2; 
    window.addEventListener("beforeunload", this.confirmSave);
      },
  destroyed () {
    window.removeEventListener("beforeunload", this.confirmSave);
  },
      methods:{
    confirmSave (event) {
      if(this.name || this.subject || this.number | this.email || this.message)
      event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
    },
        prev:function(){      
              this.view="edit";   
        },
        confirm:function(){
            

			var data={name:this.name,subject:"お申込書の送信",type:"wholesele",number:this.number,email:this.email,email_c:this.email_c,message:this.message,mode:"check"};
				
            data.version = this.appVersion;
            
			let images = document.querySelector("#upfile");
	const formData = new FormData();
   formData.append("images", images.files[0]);  // ファイル内容を詰める

	for(let d in data){
    	formData.append(d, data[d]);  // ファイル内容を詰める
	}
	
 

			var param  = {
			method: "POST",
			//headers: {"Content-Type": "application/json; charset=utf-8"	},

			// リクエストボディ
			body: formData //JSON.stringify(data)
            };
            

			window.fetch(API_URL +"wholesele", param)
			//.then(function(response){console.log(response)})
			
			.then(function(response){return response.json()})
			.then(function(data){ 
				
				console.log(data,this.mode);
				if(data!="ok"){
					this.opendialog({title:"送信エラーです。",info:data, bn_ok:"OK"});
				}else{
                    this.view="confirm";
				}
				
            }.bind(this));
            
            /*
          this.fetch("contact",aaaa).then((data)=>{
            if(data.error){
               this.opendialog({title:"送信エラーです。",info:data.error, bn_ok:"OK"});

            }else{  
              this.view="confirm";
              //alert("ありがとうございます。送信を完了しました。")
              //this.view="complete";
            }
          }) */
        },
        submit:function(){
            //name,nick,email,pref,tel,nick,time,place,message,mode");

			var data={name:this.name,subject:"お申込書の送信",type:"wholesele",number:this.number,email:this.email,email_c:this.email_c,message:this.message,mode:"submit"};
				
            data.version = this.appVersion;
            
			let images = document.querySelector("#upfile");
	const formData = new FormData();
   formData.append("images", images.files[0]);  // ファイル内容を詰める

	for(let d in data){
    	formData.append(d, data[d]);  // ファイル内容を詰める
	}
	
 

			var param  = {
			method: "POST",
			//headers: {"Content-Type": "application/json; charset=utf-8"	},

			// リクエストボディ
			body: formData //JSON.stringify(data)
            };
            

			window.fetch(API_URL +"wholesele", param)
			//.then(function(response){console.log(response)})
			
			.then(function(response){return response.json()})
			.then(function(data){ 
				
				console.log(data,this.mode);
				if(data!="ok"){
					this.opendialog({title:"送信エラーです。",info:data, bn_ok:"OK"});
				}else{
              this.view="complete";
				}
				
            }.bind(this));

            
          /*
          this.fetch("contact",{name:this.name,subject:this.subject,number:this.number,email:this.email,email_c:this.email_c,message:this.message,flow:"submit"}).then((data)=>{
            if(data.error){

               this.opendialog({title:"送信エラーです。",info:data.error,
            bn_ok:"OK"});

            }else{

            //alert("ありがとうございます。送信を完了しました。")
              this.view="complete";
            }
          })*/
        }
      }
      }
</script>

<style scoped lang="scss">
.tinfo{
    padding: 1em 0;
    font-size: .8em;
}
h1{
  margin-top: 1em;
}
.contactform_end{
  padding: 7em 0;
  text-align: center;
}
.contactform{
  padding-bottom:2em ;
  table{
  width:800px;
  max-width: 95%;
  margin: 0 auto;
  .formListTh3{
    background: #D11E1E;
    color: #fff;
  }
  th{
    padding: .7em;
    background: rgb(226, 226, 226);
    color: rgb(49, 49, 49);
    width: 13em;
  }
  td{
    padding: .7em;
    flex: 1;
  }
  }
}

@media (max-width: 768px) {
.contactform{
  table{
    width: 100%;
  th{display: block;
    width: 100%;
    text-align: left;
  }
  td{display: block;
    width: 100%;
  }
  }
}
}
</style>